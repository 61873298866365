.EventForm {
  min-width: 40vw;
  min-height: 300px;
}
.EventForm__header {
  display: flex;
  align-items: center;
  height: 36px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: #f1f3f4;
}

.EventForm__header_left {
  font-weight: bold;
}
.EventForm__header_left svg {
  margin-right: 4px;
}

.EventForm__header_right {
  margin-left: auto;
}

.EventForm__content__section {
  display: flex;
  align-items: center;
}
.EventForm__content__sectionRight {
  width: 100%;
  padding: 10px;
}

.EventForm__content__section textarea {
  background-color: #f1f3f4;
  outline: none;
  z-index: 0;
  overflow-y: visible;
  overflow-x: hidden;
  resize: none;
}

.EventForm__content__section textarea:focus {
  outline-color: transparent;
  outline-style: none;
}
.EventForm__content__section textarea::-webkit-input-placeholder {
  font-size: 14px;
}
.EventForm__content__tasks {
  display: flex;
  align-items: center;
  padding: 0px 30px;
}

.EventForm__content__tasksRight {
  margin-left: auto;
}
