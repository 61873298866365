@import url("https://fonts.googleapis.com/css?family=Lato:400,500,700,900&display=swap");
body {
  margin: 0;
  font-family: "Lato", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #ebece9 !important;
  color: #373d42;
}

h2 {
  color: #373d42;
  font-size: 28px !important;
  font-weight: 700 !important;
}
input:focus,
textarea:focus,
select:focus {
  outline: none;
}

.error {
  color: #721c24;
  margin: 0px !important;
}
h3 {
  text-transform: uppercase;
  color: #373d42;
  font-size: 14px !important;
  font-weight: bold !important;
}
h4 {
  color: #373d42;
  font-size: 15px !important;
  font-weight: bold !important;
}

#appContainer {
  height: 100%;
  min-height: 700px;
}
