.addProductForm input[type="text"],
.addProductForm input[type="textbox"] .addSKUForm input {
  padding: 5px 8px;
}

.addProductForm label,
.addSKUForm label {
  font-size: 14px;
}

button.actionBtn {
  float: right;
  background-color: rgb(0, 196, 0);
  color: rgb(255, 255, 255);
  border-radius: 10px;
  padding: 5px 15px;
  font-size: 18px;
  white-space: normal;
  max-width: 400px;
  margin: 0px auto;
  border: none;
}
.packagingUnit__Heading {
  display: flex;
  justify-content: center;
  align-items: center;
}
.alert-product-uploaded {
  display: flex;
  align-items: center;
}

.alert-product-uploaded .MuiCircularProgress-colorPrimary {
  width: 18px !important;
  height: 18px !important;
  color: #00c400;
  margin-right: 5px;
}

.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}
