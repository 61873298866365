#wrapper {
  display: flex;
  width: 100%;
  height: 100px;
}
#wrapper div {
  flex-basis: 100%;

  margin-right: 50px;
  color: #373d42;
  font-size: 32px;
  font-weight: bold;
  line-height: 36px;
  padding: 20px;
}

#wrapper div span {
  color: #757575;
  font-size: 12px;
  line-height: 14px;
  display: block;
}
