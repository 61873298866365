.external-event {
  padding: 5px 10px;
  border-radius: 2px;
  cursor: pointer;
  margin-bottom: 5px;
  color: white;
}

.navy-bg,
.bg-primary {
  background-color: #1ab394;
  color: #ffffff;
}

.draggable-outlet {
  background: #00c400;
  margin-left: 10px;
}

#external-events {
  margin-top: 20px;
  margin-bottom: 20px;
}

.Scrollable-div {
  margin-top: 20px;
}
