.Scrollable__div {
  height: 100%;
  max-height: 260px;
  overflow-y: scroll;
  margin-top: 15px;
}

.Scrollable__div p {
  color: #676a6c;
  font-size: 13px;
  margin: 0 0 10px;
}
.react-dual-listbox {
  height: 240px;
}

.outlets .form-control {
  background-color: #ffffff;
  background-image: none;
  border: 1px solid #e5e6e7;
  border-radius: 1px;
  color: inherit;
  display: block;
  padding: 6px 12px;
  transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
  width: 100%;
  height: 34px;
  font-size: 12px;
  line-height: 1.42857143;
}
.form-control::-webkit-input-placeholder {
  color: #999 !important;
  font-size: 14px;
}
.form-control:focus {
  outline: 0 !important;
  border: 1px solid #00c400 !important;
}
.table-striped .table th {
  padding: 0px 5px !important;
  vertical-align: middle;
}

[data-real-value="1"] {
  color: #00c400;
  font-weight: bold;
}
