@import url("https://fonts.googleapis.com/css?family=Lato:300,400,500,700,900&display=swap");

*:focus {
  outline: none !important;
}

/* COLORS */

.primary {
  color: rgb(0, 196, 0) !important;
}
.secondary {
  color: #373d42;
}

.btn-primary {
  background: rgb(0, 196, 0) !important;
  border: none !important;
}
.form-row .div {
  text-transform: uppercase !important;
}

/* LOGIN PAGE */
.bg-left-side {
  color: #ffffff;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
}
.bg-left-side img {
  margin-top: 50px;
  margin-left: 50px;
}
.fs-14 {
  font-size: 14px !important;
}
.fs-12 {
  font-size: 12px !important;
}

.bg-overlay {
  background: rgba(56, 64, 30, 0.7);
  overflow: hidden;
  height: 100%;
  min-height: 800px;
  z-index: 2;
  padding-bottom: 30px;
}

.footer {
  position: absolute;
  bottom: 0px;
  width: 100%;
  font-weight: 900;
  padding-left: 50px;
}
.login-wrapper-position {
  display: table;
  position: absolute;
  top: 0;
  left: 30px;
  height: 100%;
  width: 100%;
}
.login-wrapper {
  margin-top: 150px;
  margin-left: 50px;
}
.login-wrapper h2 {
  margin-bottom: 70px;
}

.login-wrapper label {
  color: #212121;
  font-size: 14px;
  font-weight: 400;
  width: 90%;
}

.auth-input {
  width: 80%;
  border: none;
  padding: 10px 10px;
  padding-left: 0px;
  color: #373d42;
  font-size: 24px;
  font-weight: 400;
  outline: none;
  background-color: #ebece9 !important;
}
::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-size: 22px;
}

.line {
  box-sizing: border-box;
  height: 2px;
  width: 90%;
  border: 1px solid #373d42;
  opacity: 0.2;
  margin-bottom: 70px;
}
.show-hide-password {
  border: none;
  background: none;
}

.btn-auth,
.btn-auth:hover,
.btn-auth:active,
.btn-auth:focus {
  border-radius: 5px;
  padding: 28px 196px;
  text-transform: uppercase;
  letter-spacing: 2.25px;
  font-size: 13px;
  font-weight: bold;
  background: rgb(0, 196, 0);
  color: #fff;
  width: 90%;
  border: none;
  outline: none;
}
.btn-auth span {
  font-weight: 900;
}

/* Change the white to any color ;) */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #ebece9 inset !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 1000px #ebece9 inset !important;
}

.noSelect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.btn:focus,
button:focus {
  outline: none !important;
}

.back-arrow {
  padding-top: 17px;
  color: #00c400;
  cursor: pointer;
}

.pointer {
  cursor: pointer;
}

.bgOverlay {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.5);
}

.metabase-frame {
  width: 98.5%;
  height: 100%;
  position: absolute;
  top: 60px;
  bottom: 10px;
  left: 10px;
}

.metabase-frame iframe {
  padding-right: 2px;
  height: 97%;
  min-height: 630px;
}

.metabase-frame-reports {
  width: 99%;
  height: 90%;
  position: absolute;
  top: 60px;
  bottom: 10px;
  left: 10px;
}

.metabase-frame-reports iframe {
  padding-right: 2px;
  height: 97%;
  min-height: 630px;
}

.home-page-frame {
  /* min-height: 650px; */
  min-height: 533px;
}

.home-page-maps {
  min-height: 650px;
}

.home-page-frame .metabase-frame {
  height: auto;
}

.home-page-frame .metabase-frame iframe {
  height: 97%;
  min-height: 551px;
}

.i-p {
  margin-right: 20px;
}

input.small-placeholder::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-size: 12px !important;
}
input.small-placeholder::-moz-placeholder {
  /* Firefox 19+ */
  font-size: 12px !important;
}
input.small-placeholder:-ms-input-placeholder {
  /* IE 10+ */
  font-size: 12px !important;
}
input.small-placeholder:-moz-placeholder {
  /* Firefox 18- */
  font-size: 12px !important;
}

.icon-big {
  transform: scale(10);
}

.leaflet-container {
  width: 100%;
  height: 30vh;
}
.react-select-custom > div,
.react-select-custom > div:hover,
.react-select-custom > div:active {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: none !important;
  border-bottom: 1px solid #ccc !important;
  padding-bottom: 5px;
  color: #373d42;

  font-size: 22px;
  font-weight: 400;
}

div.sfa-row-bg {
  background: #373d42;
  color: white;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 12px;
  padding: 10px;
  margin: 20px 10px;
}

.dp-output {
  color: #3b3737;
  font-size: 16px;
  font-weight: 600;
  text-transform: capitalize;
  letter-spacing: 2px;
}

.setPriceModal {
  min-height: 300px;
}

.MuiRadio-colorSecondary.Mui-checked:hover {
  background: none !important;
}

div.row-bg {
  background: #373d42;
  color: white;
  padding: 20px 10px;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 12px;
  margin: 10px 0px;
}
.MuiRadio-colorSecondary.Mui-checked {
  color: #00c400 !important;
}
.MuiCheckbox-colorSecondary.Mui-checked {
  color: #00c400 !important;
}

div.checkInForm .MuiTypography-body1 {
  font-family: "Lato", sans-serif;
  font-size: larger;
  color: #373d42;
  font-size: 20px;
  letter-spacing: 1px;
  line-height: 24px;
}

.textArea {
  border: none;
  border-bottom: 0.5px solid #ccc;
}
.textArea::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgba(56, 64, 30, 0.3);
}

.h3-caps-light {
  color: rgba(56, 64, 30, 0.5);
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 1px;
  text-transform: uppercase;
  line-height: 15px;
}

.p-caps-light {
  color: #373d42;
  font-family: Lato;
  font-size: 24px;
  font-weight: 300;
  letter-spacing: 2px;
  line-height: 24px;
  text-transform: uppercase;
}

.loader > svg {
  display: block;
  margin: 0 auto;
}

.tile {
  background-color: #fafafa;
  padding: 10px 25px;
  border-radius: 3px;
  margin-bottom: 15px;
}

.tile > h5 {
  color: #373d42;
  font-weight: bold;
  font-size: 28px;
  font-weight: bold;
  letter-spacing: 1.4px;
  line-height: 32px;
  margin-top: 5px;
}

.tile > span {
  color: #757575;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 14px;
  display: block;
}

.dFUnlX .MuiSvgIcon-root {
  top: 60px !important;
}

.MuiSwitch-colorSecondary.Mui-checked {
  color: #00c400;
}
.MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
  background-color: #00c400;
  opacity: 0.5;
}
.leaflet-div-icon {
  background: transparent;
  border: none;
}

.notification-bubble h5 {
  color: #000;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.75px;
  line-height: 15px;
}
.notification-bubble hr {
  margin: 10px 0px;
}
.fa-layers-counter {
  background-color: #00c400 !important;
  zoom: 160%;
}

.notification-bubble p {
  color: #373d42;
  font-size: 11px;
}
.notification-bubble .line {
  box-sizing: border-box;
  height: 2px;
  width: 100%;
  border: 1px solid #ecece9;
  margin-bottom: 0px !important;
}

.export-options {
  position: absolute;
  top: 50px;
  left: -15px;
  box-sizing: border-box;
  width: 260px;
  padding: 15px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  max-width: 500px;
  background: white;
  z-index: 1000;
}
.export-options h5 {
  font-size: 11px;
}

.export-options a,
.export-options button {
  color: rgb(46, 53, 59);
  font-size: 14px;
  font-weight: 700;
  display: block;
  margin: 10px 0px;
}
.export-options button {
  background: none;
  border: none;
  padding-left: 0px !important;
  margin-top: 5px;
}

.ReactTable .rt-thead .rt-th.-sort-asc,
.ReactTable .rt-thead .rt-td.-sort-asc {
  box-shadow: inset 0 0px 0 0 rgba(0, 0, 0, 0.6);
}

.ReactTable .rt-thead .rt-th.-sort-desc,
.ReactTable .rt-thead .rt-td.-sort-desc {
  box-shadow: inset 0 0px 0 0 rgba(0, 0, 0, 0.6);
}

div.-sort-desc::after {
  content: " -";
  float: right;
}

div.-sort-asc::after {
  content: " \25B2";
  float: right;
}
table#table thead tr th {
  cursor: pointer;
  position: relative;
  padding-right: 45px;
}

table#table thead tr th span.header-title {
  float: left;
  width: 80%;
  display: block;
}

table#table thead tr th span.sort-icon {
  position: absolute;
  opacity: 0.5;
  right: 8px;
  top: 26px;
}

.table-container {
  overflow-x: auto;
  height: 50vh;
}

#table-container > table {
  height: 100%;
}

.bg-grey {
  background: #fafafa;
  padding: 10px;
}
.border-right-div {
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}

.btn-outline {
  text-align: center;
  background: none;
  padding: 10px;
  border: 1px solid #e6e6e2;
  border-radius: 2px;
  font-weight: bold;
  font-size: 10px;
  color: #00c400;
  text-transform: uppercase;
  cursor: pointer;
}

.img-medium {
  max-height: 300px;
}
.pointer {
  cursor: pointer;
}

.date-picker-input {
  border: 1px solid #ccc;
  padding: 12px 5px 12px 20px;
  width: 70%;
  float: right;
  text-transform: lowercase;
  font-weight: 50;
}

.start_tile {
  width: 120px;
  height: 140px;
  border-radius: 4px;
  /* box-shadow: 2px 2px 4px 0 rgba(0,0,0,0.15); */
  /* margin-top:2px;
  margin-left:2px; */
  float: left;
}

.start_tile.wide {
  width: 340px;
}

.start_tile .header {
  height: 110px;
  background-color: #f4f4f4;
  border-radius: 4px 4px 0 0;
  color: 333333;
  font-weight: 300;
}

.start_tile.wide .header .left,
.start_tile.wide .header .right {
  width: 140px;
  float: left;
}

.start_tile .header .count {
  font-size: 28px;
  text-align: center;
  padding: 10px 0 0;
}

.start_tile .header .title {
  font-size: auto;
  text-align: center;
}

.start_tile .body {
  height: 60px;
  border-radius: 0 0 4px 4px;
  color: #333333;
  background-color: white;
}

.start_tile .body .title {
  text-align: center;
  font-size: auto;
  padding-top: 2%;
}

.start_tile.wide .body .title {
  padding: 4%;
}

.start_tile.count .header {
  background: linear-gradient(to bottom right, #fafafa, #fafafa);
}

.start_tile.count .body {
  color: #060807;
  font-weight: bold;
}

.start_tile.resource .header {
  background: linear-gradient(to bottom right, #ef7f00, #f7b200);
}

.start_tile.resource .body {
  color: #ef7f00;
}

.start_tile.quote .header {
  background: linear-gradient(to bottom right, #1f6abb, #4f9cf2);
}

.start_tile.quote .body {
  color: #1f6abb;
}

.start_tile.invoice .header {
  background: linear-gradient(to bottom right, #fafafa, #fafafa);
}

.start_tile.invoice .body {
  color: #060807;
  font-weight: bold;
}
.row_tile {
  margin-right: 2em;
}
/* .bg-white {
  min-height: 500px;
} */

.mapHeight {
  height: 500px;
}
.undefinedTerritory {
  color: #00c400;
  font-weight: bold;
}
