.top-bar {
  margin-bottom: 5px;
  background: #fff;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 10%), 0 2px 4px -1px rgb(0 0 0 / 6%);
  padding: 5px;
}
.collapse-icon {
  color: #fff;
  background: #373d42;
  height: 18px;
  width: 18px;
  border-radius: 2px;
  border: 3px;
  padding: 5px;
  padding-left: 2px;
  padding-top: 4px;
  cursor: pointer;
}
.collapse-icon svg {
  display: block;
  width: 0.7em;
  padding-bottom: 5px;
}

.top-nav-right-div {
  margin-right: 30px;
  display: inline;
}

.top-nav-right-div svg,
.top-nav-right-div h5 {
  display: inline;
  margin: 0px 10px;
  color: #373d42;
  font-size: 14px;
}
.top-nav-right-div svg {
  color: #373d42;
  font-weight: bold;
}
.top-nav-right-div h5 {
  font-weight: 400;
}

/* Logour */

.speech-bubble {
  background: #efefef;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  font-size: 1.2rem;
  line-height: 1.3;
  margin: 0 auto 40px;
  max-width: 400px;
  padding: 15px;
  position: relative;
}

.speech-bubble p {
  margin: 0 0 10px;
}
.speech-bubble p:last-of-type {
  margin-bottom: 0;
}

.speech-bubble::before {
  content: "";
  position: absolute;
  top: 10px;
  left: 120px;
  border-width: 0 20px 20px;
  border-style: solid;
  border-color: #efefef transparent;
  display: block;
  width: 0;
}

.logout-title {
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 24px;
  color: #373d42;
  text-transform: lowercase;
  margin-bottom: 0px;
  margin-top: 5px;
}

.logout-role {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.83px;
  color: #757575;
}

.logout-action {
  font-size: 12px;
  font-weight: bold;
  color: #00c400;
  text-align: center;
  text-transform: uppercase;
  margin-top: 20px !important;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 700ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}
