.btn-order {
  border: none;
  max-width: 200px;
  float: right;
  background: #00c400;
  padding: 10px;
  width: 100%;
  color: #fff;
  font-size: 15px;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 10px;
}

.btn-disabled {
  border: 1px solid #212529 !important;
}
