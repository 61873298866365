.Order {
  font-size: 13px;
  margin-top: 20px;
  margin-bottom: 50px;
}
.Order h5 {
  color: rgb(103, 106, 108);
  font-size: 13px;
}

.Order table {
  border: 1px solid #243745 !important;
}

.Order table thead tr {
  background: #ccc;
}

.Order table td.total-amount {
  border-top: 1px solid #e7eaec;
  border-bottom: 3px solid #000;
}
